<template>
    <div v-if="_g_UserId != 0" id="change-password-container">
        <div>
            <h1>Cambiar contraseña</h1>

            <div class="helper-input-parent">
                <span class="helper-input">Contraseña actual</span>
                <div class="password-wrapper">
                    <input v-model="old_password" class="profile-input password-input"
                        :type="show_password ? 'text' : 'password'">
                    <i class="toggle-password material-icons" @click="togglePasswordVisibility($event, 'old')">{{
                        show_old_password ?
                        'visibility_off' : 'visibility' }}</i>
                </div>
            </div>

            <div class="helper-input-parent">
                <span class="helper-input">Nueva contraseña</span>
                <div class="password-wrapper">
                    <input v-model="new_password" class="profile-input password-input"
                        :type="show_password ? 'text' : 'password'">
                    <i class="toggle-password material-icons" @click="togglePasswordVisibility($event, 'new')">{{
                        show_new_password ?
                        'visibility_off' : 'visibility' }}</i>
                </div>
            </div>

            <div class="helper-input-parent">
                <span class="helper-input">Confirmar nueva contraseña</span>
                <div class="password-wrapper">
                    <input v-model="confirm_new_password" class="profile-input password-input"
                        :type="show_password ? 'text' : 'password'">
                    <i class="toggle-password material-icons" @click="togglePasswordVisibility($event, 'confirm')">{{
                        show_confirm_password ?
                        'visibility_off' : 'visibility' }}</i>
                </div>
            </div>

            <div class="helper-checkbox-parent">
                <label>
                    <input v-model="confirm_change" type="checkbox">
                    <span>Estoy seguro de que quiero cambiar mi contraseña</span>
                </label>
            </div>

            <div @click="changePassword" class="profile-button">GUARDAR</div>
        </div>
    </div>
    <div v-else class="from-hash-change-password-container">
        <div v-if="url_flag">
            <h1>Cambiar contraseña</h1>

            <div class="helper-input-parent">
                <span class="helper-input">Correo electrónico</span>
                <div class="password-wrapper">
                    <input id="user-email" v-model="user_email" type="email" class="profile-input password-input">
                </div>
            </div>

            <div class="helper-input-parent">
                <span class="helper-input">Nueva contraseña</span>
                <div class="password-wrapper">
                    <input v-model="new_password" class="profile-input password-input"
                        :type="show_password ? 'text' : 'password'">
                    <i class="toggle-password material-icons" @click="togglePasswordVisibility($event, 'new')">{{
                        show_new_password ?
                        'visibility_off' : 'visibility' }}</i>
                </div>
            </div>

            <div class="helper-input-parent">
                <span class="helper-input">Confirmar nueva contraseña</span>
                <div class="password-wrapper">
                    <input v-model="confirm_new_password" class="profile-input password-input"
                        :type="show_password ? 'text' : 'password'">
                    <i class="toggle-password material-icons" @click="togglePasswordVisibility($event, 'confirm')">{{
                        show_confirm_password ?
                        'visibility_off' : 'visibility' }}</i>
                </div>
            </div>

            <div class="helper-checkbox-parent">
                <label>
                    <input v-model="confirm_change" type="checkbox">
                    <span>Estoy seguro de que quiero cambiar mi contraseña</span>
                </label>
            </div>

            <div @click="from_hash_changePassword" class="profile-button">GUARDAR</div>

            <div v-if="registration_success" class="success-registration-container">
                <p class="success-registration-text">
                    Ha cambiado la contraseña con éxito. Ya puede ingresar en la web con su usuario <br> y contraseña a través de este enlace:
                    <a href="/user/login"> ACCEDER </a>
                </p>
            </div>
        </div>
        <div id="main-container" class="mdc-layout-grid__inner" style="text-align: center;" v-else>
            <div class="mdc-layout-grid__cell--span-12">
                <div class="iframe-container">
                    <p>Para poder utilizar esta herramienta debe iniciar sesión.</p>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters, mapMutations } from "vuex";

export default {
    mixins: [APICaller],
    name: 'Password',
    components: {

    },
    data() {
        return {
            old_password: '',
            new_password: '',
            user_email: '',
            confirm_new_password: '',
            confirm_change: false,
            show_old_password: false,
            show_new_password: false,
            show_confirm_password: false,
            url_flag: false,
            url_hash: '',
            registration_success: false,
        };
    },
    computed: {
        ...mapGetters(["_g_UserId"]),
    },
    created() {
        this.urlMatchWith();
        this.getUrlHash();
    },
    mounted() {

    },
    methods: {
        changePassword: function () {
            if (this.checkToggleEnabled() && this.checkPasswordsAreEqual()) {
                let url = '/api/v1/change-password';
                const success = response => {
                    if(response.data.success) {
                        this.old_password = '';
                        this.new_password = '';
                        this.confirm_new_password = '';
                        this.$vueOnToast.pop('success', 'La contraseña se ha actualizado con éxito.');
                    }
                    else {
                        this.$vueOnToast.pop('error', response.data.info);
                    }
                }
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('352', 'Error cambiando la contraseña');
                const params = new URLSearchParams;
                params.append('old_password', this.old_password);
                params.append('new_password', this.new_password);
                params.append('confirm_password', this.confirm_new_password);

                this._postAPICall(url, params, successHandler, failureHandler);
            }
        },
        from_hash_changePassword: function(){
            this.user_email = document.getElementById("user-email").value;
            if(this.new_password != "" && this.confirm_new_password != "" && this.user_email != ""){
                if(this.new_password === this.confirm_new_password){
                    if (this.checkToggleEnabled() && this.checkPasswordsAreEqual()) {
                        let url = '/api/v1/from-hash-change-password';
                        const success = response => {
                            if(response.data.success) {
                                this.user_email = '';
                                this.new_password = '';
                                this.confirm_new_password = '';
                                this.registration_success = true;
                                this.$vueOnToast.pop('success', response.data.info);
                            }
                            else {
                                this.$vueOnToast.pop('error', response.data.info);
                            }
                        }
                        const successHandler = new APICaller.SuccessHandler(success);
                        const failureHandler = new APICaller.FailureHandler('352', 'Error cambiando la contraseña');
                        const params = new URLSearchParams;
                        params.append('user_email', this.user_email);
                        params.append('new_password', this.new_password);
                        params.append('confirm_password', this.confirm_new_password);
        
                        this._postAPICall(url, params, successHandler, failureHandler);
                    }
                } else {
                    this.$vueOnToast.pop('error', 'Las contraseñas no coinciden.');
                }
            } else{
                this.$vueOnToast.pop('error', 'Por favor, rellene todos los campos.');
            }
        },
        togglePasswordVisibility(event, field) {
            const passwordInput = event.target.previousElementSibling;
            switch (field) {
                case 'old':
                    this.show_old_password = !this.show_old_password;
                    passwordInput.type = this.show_old_password ? 'text' : 'password';
                    break;
                case 'new':
                    this.show_new_password = !this.show_new_password;
                    passwordInput.type = this.show_new_password ? 'text' : 'password';
                    break;
                case 'confirm':
                    this.show_confirm_password = !this.show_confirm_password;
                    passwordInput.type = this.show_confirm_password ? 'text' : 'password';
                    break;
            }
        },
        checkToggleEnabled() {
            if (this.confirm_change) {
                return true;
            }
            else {
                this.$vueOnToast.pop('error', 'Por favor, marque la casilla de confirmación.');
                return false;
            }
        },
        checkPasswordsAreEqual() {
            if (this.new_password === this.confirm_new_password) {
                return true;
            }
            else {
                this.$vueOnToast.pop('error', 'Las contraseñas no coinciden.');
                return false;
            }
        },
        urlMatchWith(){
            var url = window.location.href;
            if (url.match(/\/app\/change-password\/\?hash=.+/)) {
            this.url_flag = true;
            }
        },
        getUrlHash(){
            var url = window.location.search;
            const params = new URLSearchParams(url);
            this.url_hash = params.get('hash');
        }
    }
}
</script>
  
<style lang="scss" scoped>
h1 {
    color: var(--color-text-primary);
}

h3 {
    margin: 0;
    padding: 30px 20px 0;
}

h4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #00000B;
    background-color: #f3f4f7;
    padding: 20px 26px;
    margin-top: 30px;
    width: 75%;
}

.profile-box-icon {
    display: flex;
    align-items: center;
    height: 34px;
    margin: 20px 0;
}

.input-container-size {
    width: 100%;
}

.align-profile-img {
    display: flex;
    align-items: center;
    margin: 29px 0 35px;
}

.text-profile {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000010;
}

.text-prifle-blue {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #0080B6;
    text-decoration: none;
}

i {
    font-size: 16px;
    color: #00add8;
}

.icon-profile {
    margin-right: 13px;
    font-size: 50px;
    color: #000010;
}

.checkboxes-container {
    display: flex;
    flex-direction: column;
}

.mdc-select--outlined {
    margin: 29px 0 47px;
}

button {
    margin: 5px;
}

.input-container-size {
    margin: 20px 0;
}

.select-container-size {
    margin: 20px 0;
}

.profile-margin-checkbox {
    margin-left: 15px;
}

.profile-text-small {
    margin: 0 8px 15px;
    font-size: 12px;
    line-height: 16px;
    margin-right: 8px;
}

.profile-text-link {
    text-decoration: none;
    color: #00ADD9;
}

.profile-icon {
    cursor: pointer;
}

@media (min-width: 840px) {
    .mdc-layout-grid__cell--span-8 {
        grid-column-end: span 6;
    }
}

@media (min-width: 1280px) {
    .mdc-layout-grid__cell--span-8 {
        grid-column-end: span 4;
        margin: 0 50px;
    }
}






.profile-section-header {
    background-color: #f3f4f7;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.profile-section-parent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 300px;
}

.helper-input-parent {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 400px;
}

.helper-input {
    font-size: 12px;
}

.profile-input {
    width: 80%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #00000044;
    padding: 0px;
    background-color: white;
    box-sizing: border-box;
    font-size: 16px;
    padding-left: 10px;
    line-height: 35px;
}

.profile-text-disabled {
    background-color: #e0e1e5;
    border: 0 none;
}

.profile-input-disabled-parent {
    display: flex;
    align-items: center;
}

.help-icon {
    font-size: 20px;
    color: #00add9;
}

.pp-icon {
    font-size: 60px;
    color: black;
}

.pp-container {
    display: flex;
    align-items: center;
}

.pp-subcontainer {
    display: flex;
    flex-direction: column;
}

.pp-dialog-text {
    color: #1F7AA0;
    font-weight: bold;
}

@media (min-width: 901px) {
    .profile-parent {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        //background-color: cornflowerblue;
        height: 100%;
        align-items: center;
        margin: 50px;
    }
}

@media (max-width: 900px) {
    .container {
        flex-direction: column;
    }
}

@media (max-width: 1279px) {
    #my-profile {
        margin-left: 50px !important;
    }
}

.from-hash-change-password-container{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.profile-button {
    background-color: #00add9;
    border-radius: 20px;
    padding: 10px 50px;
    height: 20px;
    text-align: center;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 5px;
    width: 60%;
}

.profile-button:hover {
    background-color: #00a3cc;
    border-radius: 20px;
    padding: 10px 50px;
    height: 20px;
    text-align: center;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 5px;
}

.profile-section-parent {
    row-gap: 10px;
}

.profile {
    font-family: "Open Sans", sans-serif;
}

.helper-checkbox-parent input {
    accent-color: #009fc8;
}

.helper-checkbox-parent span {
    font-size: 14px;
}

.helper-checkbox-parent {
    margin-bottom: 20px;
}

.helper-checkbox-parent label {
    display: flex;
    align-items: flex-start;
}

.helper-checkbox-parent span {
    width: 300px;
    display: inline-block;
}

.terminos {
    font-size: 12px;
}

.terminos a {
    color: #00add9;
    cursor: pointer;
    margin-bottom: 10px;
}

.LOPD-parent {
    margin-bottom: 0;
}

.checkbox-bottom-helper-parent {
    margin-bottom: 10px;
}

#change-password-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#change-password-container span {
    color: var(--color-text-primary);
}

/* CSS to hide the password input by default */
.password-input {
    padding-right: 30px;
    /* Add padding to make room for the eye icon */
}

.password-input[type="password"] {
    letter-spacing: 0.3em;
    /* Use letter-spacing to obscure the text */
}

.password-input.visible[type="text"] {
    letter-spacing: normal;
    /* Show the text without letter-spacing */
}

.helper-input-parent {
    position: relative;
}

.helper-input {
    display: block;
    margin-bottom: 5px;
}

.password-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.password-input {
    padding-right: 40px;
}

.password-input[type="password"] {
    letter-spacing: 0.3em;
    /* Use letter-spacing to obscure the text */
}

.password-input.visible[type="text"] {
    letter-spacing: normal;
    /* Show the text without letter-spacing */
}

.toggle-password {
    margin-left: 10px;
    cursor: pointer;
}

.success-registration-text{
    color: green;
    font-size: 14px;
}

.success-registration-text a{
    color: #00add9 !important;
}
</style>
  